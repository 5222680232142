// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bR_k4";
export var caseStudyBackground__lineColor = "bR_kW";
export var caseStudyHead = "bR_k6";
export var caseStudyHead__imageWrapper = "bR_kT";
export var caseStudyProjectsSection = "bR_k5";
export var caseStudyQuote__bgLight = "bR_k2";
export var caseStudyQuote__bgRing = "bR_k1";
export var caseStudyVideoReview = "bR_l1";
export var caseStudyVideoReview__bgRing = "bR_l2";
export var caseStudyVideo__ring = "bR_k8";
export var caseStudy__bgDark = "bR_kS";
export var caseStudy__bgLight = "bR_kR";
export var caseStudy__bgLightReverse = "bR_l0";